import {
  CONFIG_ID_PREFIX,
  DATASET_ID_PREFIX,
  DATASET_LEGACY_ID_PREFIX,
  DATASET_VERSION_ID_PREFIX,
  EVALUATOR_ID_PREFIX,
  EVALUATOR_LEGACY_ID_PREFIX,
  EVALUATOR_VERSION_ID_PREFIX,
  FLOW_ID_PREFIX,
  FLOW_VERSION_ID_PREFIX,
  PROMPT_ID_PREFIX,
  PROMPT_VERSION_ID_PREFIX,
  TOOL_ID_PREFIX,
  TOOL_VERSION_ID_PREFIX,
} from "@/lib/constants";
import { ModelConfig, ModelConfigWithPromptTools } from "@/services/configs.service";
import { FileType } from "@/types/app/file";
import { Prompt, PromptKernelRequest, ResponseFormat } from "@/types/app/prompt";
import { isArray } from "lodash";

export function getFileTypeFromVersionId(versionId: string): FileType {
  if (versionId.startsWith(TOOL_VERSION_ID_PREFIX)) {
    return "tool";
  }
  if (versionId.startsWith(PROMPT_VERSION_ID_PREFIX) || versionId.startsWith(CONFIG_ID_PREFIX)) {
    return "prompt";
  }
  if (versionId.startsWith(DATASET_VERSION_ID_PREFIX)) {
    return "dataset";
  }
  if (versionId.startsWith(EVALUATOR_VERSION_ID_PREFIX)) {
    return "evaluator";
  }
  if (versionId.startsWith(FLOW_VERSION_ID_PREFIX)) {
    return "flow";
  }
  throw new Error(`Unknown version type for ID: ${versionId}`);
}

export const getFileTypeFromId = (id: string): FileType => {
  if (id.startsWith(PROMPT_ID_PREFIX)) {
    return "prompt";
  } else if (id.startsWith(TOOL_ID_PREFIX)) {
    return "tool";
  } else if (id.startsWith(DATASET_ID_PREFIX) || id.startsWith(DATASET_LEGACY_ID_PREFIX)) {
    return "dataset";
  } else if (id.startsWith(EVALUATOR_ID_PREFIX) || id.startsWith(EVALUATOR_LEGACY_ID_PREFIX)) {
    return "evaluator";
  } else if (id.startsWith(FLOW_ID_PREFIX)) {
    return "flow";
  } else {
    throw new Error(`Unknown file type for id: ${id}`);
  }
};

export const getModelConfigFromPromptVersion = (version: PromptKernelRequest | Prompt): ModelConfigWithPromptTools => {
  // This is an explicit list WHICH IS A HUGE HAZARD because the Prompt will have loads of extra fields.
  return {
    model: version.model,
    provider: version.provider,
    endpoint: version.endpoint,
    template_language: version.template_language ?? undefined,
    reasoning_effort: version.reasoning_effort ?? undefined,
    max_tokens: version.max_tokens ?? undefined,
    temperature: version.temperature ?? undefined,
    top_p: version.top_p ?? undefined,
    stop: version.stop ?? undefined,
    frequency_penalty: version.frequency_penalty ?? undefined,
    presence_penalty: version.presence_penalty ?? undefined,
    seed: version.seed ?? undefined,
    response_format: version.response_format ?? undefined,
    other: version.other ?? null,
    prompt_template: typeof version.template === "string" ? version.template : undefined,
    chat_template: Array.isArray(version.template) ? version.template : undefined,
    tools: version.tools ?? [],
    // TODO: I think we should just standardise on objects for linked tools which includes id and version/environment
    // Currently we get the object back on the response, and we send just the id on the request
    linked_tools: isArray(version.linked_tools)
      ? version.linked_tools?.map((tool) => (typeof tool === "object" ? tool.id : tool))
      : [],
  };
};

/**
 * Convert a `ModelConfig` to a `PromptKernelRequest`.
 *
 * Note that this omits the `tools` field, as this cannot be converted easily.
 * `ModelConfig` has `tools?: (EditorTool | LinkedTool)[];`
 * `PromptKernelRequest` has `{ tools?: ToolFunction[] | null; linked_tools?: string[] | null; }`
 *
 */
export const getPromptVersionParametersFromModelConfig = (modelConfig: ModelConfig): PromptKernelRequest => {
  const {
    model,
    provider,
    endpoint,
    prompt_template,
    chat_template,
    max_tokens,
    temperature,
    top_p,
    stop,
    frequency_penalty,
    presence_penalty,
    seed,
    response_format,
    other,
  } = modelConfig;
  const template = (endpoint === "chat" ? chat_template : prompt_template) ?? null;
  return {
    model: model || "gpt-turbo-3.5",
    provider: provider || "openai",
    endpoint: endpoint || "chat",
    template,
    max_tokens,
    temperature,
    top_p,
    stop,
    frequency_penalty,
    presence_penalty,
    seed,
    response_format: response_format as ResponseFormat,
    other,
  };
};
